<template>
  <div>
    <Header :userlist="userlist" @changeState="changeStateHandle" />
    <div class="wrapper">
      <div class="bg1">
        <!-- 首页 -->
        <Mains v-if="state === 0" :userlist="userlist" @changeState="changeStateHandle" />
        <!-- 个人资料 -->
        <UserSet
          v-if="state === 1"
          :navTab="navTab"
          :userlist="userlist"
          @change="change"
          @changeState="changeState"
        />
        <!-- 联系客服 -->
        <Service v-if="state === 2" />
     <!--  复诊
        <Further v-if="state === 3" @changeState="changeState" /> -->
		<!-- 设置 -->
		<SetTime v-if="state==3" :type="0"></SetTime>
        <!-- 医生在线 -->
        <Online v-if="state === 4" title="医生在线" timeType="问诊工作时间 : " @changeState="changeState" />
        <!-- 功能反馈-->
        <Feedback v-if="state === 5" @changeState="changeState" />
        <!-- 我的服务 -->
        <MySerivce v-if="state === 6" />
        <!-- 我的药方 -->
        <Prescription v-if="state === 7" />
        <!-- 我的评价 -->
        <Evaluate v-if="state === 8" />
        <!-- 我的钱包 -->
        <Wallet v-if="state === 9" />
        <!-- 消息通知 -->
        <SysMessage v-if="state === 10" />
		<!-- 不良上报 -->
		<BadReport v-if="state==11"></BadReport>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./header";
import Footer from "@/components/public/public_bottom";
import Mains from "./main"; // 首页
import UserSet from "@/components/personal_repect/userData"; // 个人资料
import SetTime from "@/components/personal_repect/SetTime"; // 个人资料
import Feedback from "@/components/personal_repect/feedback"; // 功能反馈
import Service from "@/components/personal_repect/service"; // 联系客服
import MySerivce from "@/components/personal_repect/myService"; // 我的服务
import Evaluate from "@/components/personal_repect/evaluate"; // 我的评价
import Prescription from "@/components/personal_repect/prescription"; // 我的药方
import Further from "@/components/personal_repect/further"; // 复诊
import Online from "@/components/personal_repect/online"; // 医生在线
import Wallet from "@/components/personal_repect/wallet/wallet";
import SysMessage from "@/components/personal_repect/sysMessage";
import BadReport from"@/components/personal_repect/badreport"

import { usergetinfo } from "@/request/user";
export default {
  components: {
    Header,
    Footer,
    Mains,
    UserSet,
    Feedback,
    Service,
    MySerivce,
    Prescription,
    Evaluate,
    Further,
    Online,
    Wallet,
    SysMessage,
	BadReport,
	SetTime
  },
  data() {
    return {
      state: 0,
      navTab: 0,
      userlist: {}
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.query.msg) {
        vm.state = 10;
      }
    });
  },
  created() {
    if (this.$route.query.state) {
      this.state = parseInt(this.$route.query.state);
    }
    usergetinfo({ token: this.$token }).then(res => {
      this.userlist = res.data;
    });
  },
  methods: {
    changeStateHandle(num, id) {
      this.state = num;

      if (num === 9 && this.state === 9) {
        this.state = 100;
        this.$nextTick(() => {
          this.state = 9;
        });
      }
      if (this.state == 1) {
        this.navTab = 0;
      }
    },
    changeState(num) {
      this.state = num;
    },
    change(num) {
      this.navTab = num;
    }
  }
};
</script>

<style scoped>
.bg1 {
  width: 1200px;
  margin: 0 auto;
}
.wrapper {
  background: #f5f5f5;
  padding: 40px 0px;
}
</style>