<template>
	<div class="box">
		<div class="title">设置工作时间</div>
		<div class="settime">
			<div>上午上班时间
				<el-time-select v-model="beforestart" :picker-options="{start: '00:00',step: '00:15',end: '12:00'}" placeholder="选择时间"></el-time-select>
				~
				<el-time-select v-model="beforeend" :picker-options="{start: '00:00',step: '00:15',end: '12:00',  minTime: beforestart}"
				 placeholder="选择时间"></el-time-select>
			</div>
			<div>下午上班时间
				<el-time-select v-model="afterstart" :picker-options="{start: '12:00',step: '00:15',end: '24:00'}" placeholder="选择时间"></el-time-select>
				~
				<el-time-select v-model="afterend" :picker-options="{start: '12:00',step: '00:15',end: '24:00',minTime: afterstart}"
				 placeholder="选择时间"></el-time-select>
			</div>
			<div>
				<button @click="setTime">保存</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SetTime",
		props:["type"],
		data() {
			return {
				beforestart: "",
				beforeend: "",
				afterstart: "",
				afterend: "",
			}
		},
		created() {
			this.getTime();
		},
		methods: {
			getTime() {
				this.$axios.post("api/doctor/getDutyStatus", {
					token: this.$token
				}).then(res => {
					if (res.data.code == 1) {
						let text = res.data.data;
						this.beforestart = text.morning_time.slice(0, 4);
						this.beforeend = text.morning_time.slice(6);
						this.afterstart = text.afternoon_time.slice(0, 5);
						this.afterend = text.afternoon_time.slice(6);
					}
				})
			},
			setTime() {
				this.$axios.post("/api/doctor/setworktime", {
					token: this.$token,
					morning_time: this.beforestart + "~" + this.beforeend,
					afternoon_time: this.afterstart + "~" + this.afterend,
				}).then(res => {
					if (res.data.code == 1) {
						this.$message.success("设置成功");
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.box {
		width: 800px;
		height: 300px;
		background-color: #fff;

		.title {
			padding: 10px;
			border-bottom: 1px solid #EAEAEA;
		}

		.settime {
			padding: 20px 40px;

			div {
				margin-bottom: 10px;
				font-size: 14px;

				/deep/.el-input__inner {
					border: 1px solid #E5E5E5 !important;
					border-radius: 5px;
					width: 120px;
				}

				.el-date-editor.el-input,
				.el-date-editor.el-input__inner {
					width: 120px;
					height: 30px;

				}

				button {
					width: 80px;
					height: 30px;
					margin-top: 20px;
					cursor: pointer;
				}
			}
		}
	}
</style>
