<template>
  <div>
    <!-- 公告 header -->
    <div class="indexUse_header">
      <div class="wrap headerBox">
        <div class="logo" @click="changeHomePage">
          <img src="../../../assets/image/funuo.png" alt />
          孚诺大药房
        </div>
        <div class="nav">
          <div @click="$router.push('/home/doctor/index')">首页</div>
          <div
            @mouseover="headerTab = 1"
            @mouseout="headerTab = -1"
            @click="commitHandle(0)"
            :class="{userArrActives : headerTab ==1}"
          >个人中心</div>
          <div
            @mouseover="headerTab = 2"
            @mouseout="headerTab = -1"
            :class="{userArrActives : headerTab ==2}"
          >
            服务
            <img v-show="headerTab != 2" src="@/assets/image/top_Open_icon@2x.png" alt />
            <img v-show="headerTab == 2" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab ==2"></i>
            <div v-show="headerTab ==2" class="server">
              <div
                @click="commitHandle(i + 6)"
                @mouseover="userArrActive = i"
                @mouseout="userArrActive = -1"
                :class="{userArrActive : userArrActive ==i}"
                v-for="(v,i) in userArr"
                :key="i"
                class="list"
              >
                <img v-show="userArrActive ==i" :src="v.img1" alt />
                <img v-show="userArrActive !=i" :src="v.img2" alt />
                {{v.txt}}
              </div>
            </div>
          </div>
          <div
            @mouseover="headerTab = 3"
            @mouseout="headerTab = -1"
            :class="{userArrActives : headerTab ==3}"
          >
            消息
            <img v-show="headerTab != 3" src="@/assets/image/top_Open_icon@2x.png" alt />
            <img v-show="headerTab == 3" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab == 3"></i>
            <div v-show="headerTab == 3" class="server news">
              <p
                class="one"
                @click="lookMessage"
                v-if="messages && messages.length"
              >{{ messages[0].title }}: {{ messages[0].content}}</p>
              <p v-if="messages.length === 0">消息提示: 暂无更多消息</p>
              <p class="goAllNews" @click="lookMessage">查看全部消息</p>
            </div>
          </div>
        </div>
        <div
          class="ffr"
          @mouseover="headerTab = 4"
          @mouseout="headerTab = -1"
          :class="{userArrActives : headerTab ==4}"
        >
          <div class="ffrs">
            {{ userlist.user_login }}
            <img
              v-show="headerTab != 4"
              src="@/assets/image/top_Open_icon@2x.png"
              alt
            />
            <img v-show="headerTab == 4" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab == 4"></i>
            <div v-show="headerTab == 4" class="server">
              <div
                @click="changeLandPage(v, i)"
                @mouseover="userArrActive = i"
                @mouseout="userArrActive = -1"
                :class="{userArrActive : userArrActive ==i}"
                v-for="(v,i) in myArr"
                :key="i"
                class="list"
              >
                <img v-show="userArrActive ==i" :src="v.img1" alt />
                <img v-show="userArrActive !=i" :src="v.img2" alt />
                {{v.txt}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="indexUse_header_min">
      <div class="wrap">
        <img
          v-if="!isImgError && userlist.avatar != ''"
          :src="userlist.avatar"
          class="myHeaderImg fl"
          @error="isImgError = true"
        />
        <img
          v-else
          src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
          class="myHeaderImg fl"
        />
        <div class="fl myName">
          <p>医生名称</p>
          <p>
            <img @click="commitHandle(1)" src="@/assets/image/top_Basicinformation_icon@2x.png" alt />
			<img @click="commitHandle(3)"src="../../../assets/image/top_pen_icon@2x.png" alt="">
          </p>
        </div>
        <div class="fr fns fn">
<!--          <p @click="commitHandle(9)">
            <img src="@/assets/image/top_wallet_icon@2x.png" alt />我的钱包
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMessageList } from "@/request/personnel";
import { setDuty } from "@/request/personnel";
import { getDutyStatus } from "@/request/personnel";
export default {
  props: ["userlist"],
  data() {
    return {
      headerTab: -1,
      nav: -1,
      userArr: [
        {
          txt: "电子病例",
          img1: require("@/assets/image/top_Open_Interrogation_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Interrogation@2x.png")
        },
        {
          txt: "我的药方",
          img1: require("@/assets/image/top_Open_Doctor_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Doctor@2x.png")
        },
        {
          txt: "我的评价",
          img1: require("@/assets/image/top_Open_Medication_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Medication@2x.png")
        }
      ],
      myArr: [
        {
          txt: "",
          img1: require("@/assets/image/top_Open_Order_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Order@2x.png")
        },
        {
          txt: "修改资料",
          img1: require("@/assets/image/top_Open_Setup_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Setup@2x.png")
        },
        {
          txt: "个人中心",
          img1: require("@/assets/image/top_Open_Doctor_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Doctor@2x.png")
        },
        {
          txt: "退出",
          img1: require("@/assets/image/top_Open_Signout_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Signout@2x.png")
        }
      ],
      userArrActive: -1,
      messages: "",
      isImgError: false // 图片是否加载失败
    };
  },
  created() {
    getMessageList({
      token: this.$token,
      type: 0 // 0代表获取系统消息
    }).then(res => {
      this.messages = res.data.data;
    });
    let temp = this.myArr[0];
    getDutyStatus({ token: this.$token }).then(res => {
      if (res.code == 1) {
        if (res.data.is_duty == 0) {
          temp.txt = "医生上线";
        } else {
          temp.txt = "医生下线";
        }
      }
    });
  },
  methods: {
    commitHandle(num) {
      this.$emit("changeState", num);
    },
    changeHomePage() {
      this.$router.push("/home/doctor/index");
    },
    lookMessage() {
      this.$emit("changeState", 10);
    },
    changeLandPage(v, i) {
      if (i == 0) {
        getDutyStatus({ token: this.$token }).then(res1 => {
          // console.log(res1)
          if (res1.code == 1) {
            setDuty({
              token: this.$token,
              is_duty: res1.data.is_duty == 0 ? 1 : 0
            }).then(res => {
              console.log(res)
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: res1.data.is_duty == 0 ? "你已上线" : "你已下线"
                });
                this.myArr[0].txt =
                  res1.data.is_duty == 0 ? "医生下线" : "医生上线";
              }
            });
          }
        });
      } else if (i == 1) {
        this.$emit("changeState", 1);
      } else if (i == 2) {
        this.$emit("changeState", 0);
      } else if (i === 3) {
        this.$router.push("/land");
        localStorage.removeItem("lzyyToken");
        this.$store.state.isLogin = false;
        this.signOutSocket();
      }
    },
    signOutSocket() {
      let xiaxian = {
        method: "logout"
      };
      if (this.$store.state.sendMessage) {
        this.$store.state.sendMessage(JSON.stringify(xiaxian));
      }
    }
  }
};
</script>
<style lang="less" scoped>
// @import "./index";
.indexUse_header {
  cursor: Pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  line-height: 66px;
  background: white;
  z-index: 9;
  .headerBox {
    display: flex;
    position: relative;
  }
  .logo {
    font-size: 24px;
    img {
      width: 44px;
      height: 44px;
      float: left;
      margin-top: 10px;
      margin-right: 12px;
    }
  }
  .nav {
    display: flex;
    margin-left: 110px;
  }
  .nav > div {
    text-align: center;
    width: 120px;
    position: relative;
    cursor: pointer;
    i {
      position: absolute;
      display: block;
      bottom: -10px;
      left: 50px;
      width: 20px;
      height: 20px;
      background: white;
      transform: rotate(45deg);
      z-index: 1;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
    }
  }
  .server {
    position: absolute;
    top: 66px;
    width: 176px;
    left: -28px;
    z-index: 2;
	padding-bottom: 40px;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
  }
  .server > .list {
    color: #000;
    cursor: pointer;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    img {
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }
  }
  .server > .list:nth-child(4){
    img {
      margin-left: -30px;
    }
  }
  .userArrActives {
    color: #4eeec8;
  }
  .userArrActive {
    color: #4eeec8 !important;
  }
  .news {
    color: #000;
    width: 364px;
    // height: 150px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    p {
      background: #f5f5f5;
      text-align: left;
      font-size: 14px;
      height: 42px;
      line-height: 42px;
      padding-left: 10px;
      margin-bottom: 2px;
      cursor: pointer;
    }
    .goAllNews {
      margin-top: 10px;
      background: white;
      color: #4eeec8;
      text-align: center;
    }
  }
  .ffr {
    position: absolute;
    top: 0;
    right: 0;
    img {
      vertical-align: middle;
    }
    > .ffrs {
      position: relative;
    }
    i {
      position: absolute;
      display: block;
      bottom: -10px;
      left: 22px;
      width: 20px;
      height: 20px;
      background: white;
      transform: rotate(45deg);
      z-index: 1;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
    }
    .server {
      text-align: center;
      left: -58px !important;
    }
  }
}

// header min
.indexUse_header_min {
  margin-top: 68px;
  height: 120px;
  background: #4eeec8;
  > div {
    line-height: 120px;
  }
  .myHeaderImg {
    margin-top: 20px;
    width: 80px;
    height: 80px;
  }
  .myName {
    margin-left: 20px;
    padding-top: 28px;
    font-size: 18px;
    p {
      height: 30px;
      line-height: 30px;
      color: white;
    }
    p:nth-of-type(2) {
      margin-top: 10px;
    }
    img {
      width: 30px;
      margin-right: 10px;
      height: 30px;
      cursor: Pointer;
    }
  }
  .fn {
    margin-left: 20px;
    padding-top: 22px;
    p {
      cursor: Pointer;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding-left: 14px;
      padding-right: 14px;
      border: 1px solid white;
      background: rgba(255, 255, 255, 0.2);
      color: white;
      margin-right: 18px;
      border-radius: 4px;
    }
  }
  .fns {
    img {
      margin-right: 8px;
    }
    p {
      margin-right: 0;
      margin-left: 18px;
    }
  }
}
</style>