<template>
	<div class="box" v-if="state===1">
		<div class="top">
			<h4>不良信息上报列表</h4>
			<span class="btn" @click="addinfor">添加</span>
		</div>
		<div class="list">
			<table>
				<tr class="list_title">
					<th>标题</th>
					<th>时间</th>
				</tr>
				<tr v-for="(item,index) in tableData" :key="item.id" @click="listinfor(item.id)">
					<td class="list_content">{{ item.subject}}</td>
					<td class="list_content">{{ item.add_time | formatTime}}</td>
				</tr>
			</table>
		</div>
	</div>
	<div class="box infor" v-else>
		<div class="top">
			<h4>不良信息上报列表</h4>
			<span class="btn" @click="addinfor">返回</span>
		</div>
		<div class="list">
			<div class="list-item">
				<span class="tltle">不良信息类型</span>
				<el-select v-model="type" placeholder="请选择" :disabled="infor">
					<el-option  v-for="item in badcasetype" :key="item.code" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</div class="list-item">
			<div class="list-item">
				<span class="tltle">事件等级</span>
				<el-select v-model="level" placeholder="请选择" :disabled="infor">
					<el-option v-for="item in badcaselevel" :key="item.code" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</div>
			<div class="list-item">
				<span class="tltle">事件主题</span>
				<el-input v-model="theme" placeholder="请输入内容" :disabled="infor"></el-input>
			</div>
			<div class="list-item">
				<span class="tltle">事件内容</span>
				<el-input v-model="content" placeholder="请输入内容" :disabled="infor"></el-input>
			</div>
			<div class="list-item">
				<span class="tltle">互联网诊疗号</span>
				<el-select v-model="caseId" placeholder="请选择" :disabled="infor">
					<el-option v-for="item in InquiryList" :key="item.inquiry_id" :label="item.p_code" :value="item.inquiry_id" >
						<span style="float: left">{{ item.realname }}</span>
						<span style="display: inline-block;margin: 0 20px;"></span>
						<span style="float: right; color: #8492a6; font-size: 13px">{{ item.add_time | formatTime}}</span>
					</el-option>
				</el-select>
			</div>
			<div class="list-item">
				<span class="tltle">事件发生时间</span>
				<el-date-picker v-model="selectDay" type="date" placeholder="选择日" @change="dataSearch" value-format="yyyy-MM-dd" :disabled="infor"></el-date-picker>
				<div style="width: 20px; display: inline-block;"></div>
				<el-time-select v-model="time" :picker-options="{
				    start: '00:00',
				    step: '00:10',
				    end: '23:00'
				  }"
				 placeholder="选择时间" :disabled="infor">
				</el-time-select>
			</div>
			<div class="list-item">
				<span class="tltle">事件发生地址</span>
				<el-select v-model="areaname" filterable placeholder="请选择" :disabled="infor">
					<el-option v-for="item in citylist" :key="item.code" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</div>
			<div class="list-item">
				<span class="tltle">事件结论</span>
				<el-input v-model="result" placeholder="请输入内容" :disabled="infor"></el-input>
			</div>
			<div class="list-item">
				<span class="tltle">上报事件流程状态</span>
				<el-select v-model="status" placeholder="请选择" :disabled="infor">
					<el-option v-for="item in badcasestatus" :key="item.code" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</div>
		</div>
		<el-button type="primary" @click="submission" v-show="infor==true?false:true">确定</el-button>
	</div>

</template>

<script>
	import {
		getBedInfor
	} from "@/request/personnel";
	import {
		subBedInfor
	} from "@/request/personnel";
	import {
		getNumberList
	} from "@/request/personnel";
	import {
		getBadList
	} from "@/request/personnel";
	import {
		getBadInfo
	} from "@/request/personnel";
	export default {
		data() {
			return {
				infor:false,
				badlist: [],
				areacode: "", //城市id
				areaname: "", //城市名
				userlist: {},
				dialogVisible: false,
				selectDay: "", //日期
				time: "", //时间
				theme: "", //事件主题
				content: "", //内容
				caseId: "", //互联网诊号
				result: "", //结论
				state: 1,
				value: '',
				tableData: [],
				citylist: [],
				badcasetype: [],
				type: "", //类型
				typecode: "",
				badcaselevel: [],
				level: "", //等级
				levelcode: "",
				badcasestatus: [],//状态列表
				status: "", //上报状态
				caseId: "",
				statuscode: "",
				InquiryList: [],
				options: [],
			}
		},
		filters: {
			formatTime: function(value) {
				let time;
				var times = new Date(value * 1000);
				let y = times.getFullYear();
				let M = times.getMonth() + 1;
				let d = times.getDate();
				let h = times.getHours();
				let m = times.getMinutes();
				let s = times.getSeconds();
				M = M >= 10 ? M : ('0' + M);
				d = d >= 10 ? d : ('0' + d);
				h = h >= 10 ? h : ('0' + h);
				m = m >= 10 ? m : ('0' + m);
				s = s >= 10 ? s : ('0' + s);
				time = y + "-" + M + "-" + d + "  " + h + ":" + m + ":" + s;
				return time;
			}
		},
		created() {
			getBadList({
				token: this.$token
			}).then(res => {
				this.tableData = res.data.data;
			});
			this.initData({});
			getBedInfor({
				table: "BADCASELEVEL"
			}).then(res => {
				this.badcaselevel = res.data;
			});
			getBedInfor({
				table: "BADCASETYPE"
			}).then(res => {
				this.badcasetype = res.data;
			});
			getBedInfor({
				table: "REGION"
			}).then(res => {
				this.citylist = res.data;
			});
			getBedInfor({
				table: "BADCASESTATUS"
			}).then(res => {
				this.badcasestatus = res.data;
			});
			getNumberList({
				token: this.$token
			}).then(res => {
				
				this.InquiryList = res.data;

			});
		},
		methods: {
			//列表详情
			listinfor(id){
				this.infor=true;
				this.state=2;
				getBadInfo({
					token: this.$token,
					id:id
				}).then(res => {
					let infor=res.data;
					this.type=infor.typeCode_name;
					this.level=infor.levelCode_name;
					this.theme=infor.subject;
					this.content=infor.comment;
					this.caseId=infor.seqno;
					this.selectDay=infor.eventTime.slice(0,10);
					this.time=infor.eventTime.slice(10);
					this.areaname=infor.areaCode_name;
					this.result=infor.result;
					for(let i in this.badcasestatus){
						if(this.badcasestatus[i].code==infor.status){
							this.status=this.badcasestatus[i].name;
						}
					}
				});
			},
			//获取code
			findCode(list, key) {
				let code;
				for (let item in list) {
					if (list[item].name == key) {
						code = list[item].code;
					}
				}
				return code;
			},
			//获取p_code
			getnumber(list, id) {
				let code;
				for (let item in list) {
					if (list[item].inquiry_id == id) {
						code = list[item].p_code;
					}
				}
				return code;
			},
			dataSearch() {
				this.getListByDay();
			},
			//当前时间
			async initData(data) {
				//获取当前时间
				var now = new Date();
				var monthn = now.getMonth() + 1;
				var yearn = now.getFullYear();
				var dayn = now.getDate();
				var h = now.getHours();
				var m = now.getMinutes();
				this.selectDay = yearn + "-" + monthn + "-" + dayn;
				this.time = h + ":" + m;
			},
			async getListByDay(data) {},
			addinfor() {
				this.initData({});
				this.state == 1 ? this.state = 2 : this.state = 1;
				this.infor=false;
				this.type="";
				this.level="";
				this.theme="";
				this.content="";
				this.caseId="";
				this.areaname="";
				this.result="";
				this.status="";
			},
			// 提交
			submission() {
				if (this.type === "") {
					this.$message({
						type: "error",
						message: "请输入不良信息类型"
					})
					return
				}
				if (this.level === "") {
					this.$message({
						type: "error",
						message: "请输入事件等级"
					})
					return
				}
				if (this.theme === "") {
					this.$message({
						type: "error",
						message: "请输入事件主题"
					})
					return
				}
				if (this.content === "") {
					this.$message({
						type: "error",
						message: "请输入事件内容"
					})
					return
				}
				if (this.caseId === "") {
					this.$message({
						type: "error",
						message: "请输入互联网问诊号"
					})
					return
				}
				if (this.selectDay === "") {
					this.$message({
						type: "error",
						message: "请输入事件发生日期"
					})
					return
				}
				if (this.time === "") {
					this.$message({
						type: "error",
						message: "请输入事件发生时间"
					})
					return
				}
				if (this.areaname === "") {
					this.$message({
						type: "error",
						message: "请输入事件发生地址"
					})
					return
				}
				if (this.result === "") {
					this.$message({
						type: "error",
						message: "请输入事件结论"
					})
					return
				}
				if (this.status === "") {
					this.$message({
						type: "error",
						message: "请输入事件处理状态"
					})
					return
				}
				subBedInfor({
					token: this.$token,
					caseId: this.caseId,
					seqno: this.getnumber(this.InquiryList, this.caseId),
					areaCode: this.findCode(this.citylist, this.areaname),
					areaCode_name: this.areaname,
					typeCode: this.findCode(this.badcasetype, this.type),
					typeCode_name: this.type,
					levelCode: this.findCode(this.badcaselevel, this.level),
					levelCode_name: this.level,
					subject: this.theme,
					comment: this.content,
					eventTime: this.selectDay + " " + this.time + ":00",
					result: this.result,
					status: this.findCode(this.badcasestatus, this.status),
				}).then(res => {
					if(res.code==1){
						this.$message({
						  type: "success",
						  message: "提交成功"
						});
						this.addinfor();
						getBadList({
							token: this.$token
						}).then(res => {
							this.tableData = res.data.data;
						});
					}
				});
				
			},

		}

	}
</script>

<style>
	.list table{
		width: 100%;
		border:2px solid #e5e5e5;
		  border-collapse: collapse;
	}
	.list_title{
		width: 100%;
		
	}
	.list_title th{
		width: 50%;
		height: 40px;
		color:#4d4d4d;
		border:2px solid  #e5e5e5;
		background-color: #f7f7f7;
		height: 40px;
	}
	.list  td{
		width: 50%;
		height: 40px;
		padding: 0 20px;
		color:#4d4d4d;
		border:2px solid #e5e5e5;
	}
	
	.box {
		width: 1200px;
		background-color: #fff;
		margin: 0 auto;
		border: 1px solid #f0f0f0;
	}

	.top {
		line-height: 40px;
		border-bottom: 2px solid #f0f0f0;
		padding: 10px 20px;
		display: flex;
		justify-content: space-between;
	}

	.top .btn {
		color: #67f0d0;
		margin-right: 10px;
		cursor: pointer;
	}

	.list {
		padding: 30px;
		width: 100%;
	}

	.infor .list .list-item {
		margin-bottom: 20px;
	}

	.infor .list .tltle {
		width: 200px;
		color: #4d4d4d;
		display: inline-block;
	}

	.infor .list>div /deep/.el-input__suffix-inner {
		/* border:1px solid #eaeaea; */
		text-align: right;
	}

	.infor /deep/.el-input {
		width: 200px;
	}

	.infor /deep/.el-input__inner {
		width: 200px;
		border: 1px solid #eaeaea !important;
	}

	.infor /deep/ .el-button--primary {
		background-color: #4EEEC8;
		border-color: #4EEEC8;
		margin-left: 34px;
		margin-bottom: 40px;
	}
</style>
